import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  inject,
} from '@angular/core';

interface IKycAccordion {
  key: string;
  isAccordionOpen: boolean;
  accordionTitle: string;
}

@Component({
  selector: 'app-kyc-accordion',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './kyc-accordion.component.html',
  styleUrl: './kyc-accordion.component.scss',
})
export class KycAccordionComponent implements OnInit, AfterViewInit {
  @Input({ required: true }) accordionKey!: string;
  @Input() isAccordionOpen: boolean = false;
  @Input({ required: true }) accordionTitle!: string;

  @Input() isKycComplete: boolean | null = null;

  renderer = inject(Renderer2);
  elRef = inject(ElementRef);
  cdRef = inject(ChangeDetectorRef);

  kycPage!: IKycAccordion;

  constructor() {}

  ngOnInit(): void {
    this.kycPage = {
      key: this.accordionKey,
      isAccordionOpen: this.isAccordionOpen,
      accordionTitle: this.accordionTitle,
    };
  }

  ngAfterViewInit(): void {
    for (const key in this.kycPage) {
      this.renderer.listen(
        this.elRef.nativeElement.querySelector(`#${this.kycPage.key}`),
        'click',
        () => this.openAccordion()
      );
    }
  }

  openAccordion(): void {
    this.kycPage.isAccordionOpen = !this.kycPage.isAccordionOpen;

    this.cdRef.detectChanges();
  }
}
