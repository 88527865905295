<div class="accordion px-4 py-6">
  <div
    [id]="kycPage.key"
    class="accordion__heading flex cursor-pointer justify-between"
  >
    <h3
      [ngClass]="{ 'mb-6': kycPage.isAccordionOpen }"
      class="text-lg font-medium"
    >
      {{ accordionTitle }}

    </h3>
    <div class="flex items-center mr-5">
      <span
        *ngIf="isKycComplete !== null"
        [ngClass]="{
          'bg-green-100 text-mango-pineGreen': isKycComplete,
          'bg-red-100 text-mango-sharpRed': !isKycComplete
        }"
        class="w-fit items-center rounded-full px-2 py-1 text-xs font-medium mr-2"
      >
        {{ isKycComplete ? 'Completed' : 'Incomplete' }}
      </span>
      <img
        src="assets/images/svgs/chevron-down-arrow.svg"
        alt=""
        [ngClass]="{
          'rotate-180': kycPage.isAccordionOpen
        }"
        class="accordion--toggle-icon"
      />

    </div>
  </div>
  <div
    [ngClass]="{
      hidden: !kycPage.isAccordionOpen,
      'is-open': kycPage.isAccordionOpen
    }"
    class="accordion__wrapper w-full"
  >
    <div
      [ngClass]="[
        kycPage.isAccordionOpen
          ? 'rounded-lg border border-solid border-mango-gray200 px-[2.06rem] py-[2.12rem] shadow-[0_1px_2px_0_rgba(16,24,40,0.06)]'
          : ''
      ]"
      class="inner accordion__content"
    >
      <ng-content></ng-content>
    </div>
  </div>
</div>
